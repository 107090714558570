import React from "react";
import { view } from "@risingstack/react-easy-state";
import globalStore from "./globalStore";
import { saveAs } from "file-saver";

let exporter = require("./exporter/exporter");

// https://quip.com/api/personal-token

class App extends React.Component {
  startExporting() {
    globalStore.zipFileFinished = false;
    if(!globalStore.running && !globalStore.exportPaused && !globalStore.exportPausedByUser){
      globalStore.finished = false;
      let at = document.getElementById("accesstoken").value;
      exporter.startExporting(at);
    } else if (globalStore.exportPausedByUser){
      globalStore.exportPausedByUser = false;
      globalStore.running = true;
    }
  }

  downloadZip() {
    saveAs(globalStore.zipFile, globalStore.rootDir);
  }

  pauseExportingByUser(){
    globalStore.zipFileFinished = false;
    globalStore.exportPausedByUser = true;
    globalStore.running = false;
  }

  render() {
    return (
      <div className="container">
        <div className="pure-g">
          <div className="pure-u-1 center">
            <br/>
            <h1>Sentral Quip Export Tool</h1>
            <p className="description">
              <b>This tool</b> will perform a <
              span className="highlight">full export</span>{" "}
              of your Quip account's{" "}
              <span className="highlight">private &amp; shared folders</span>,
              and export all documents at once (including image files)
            </p>
            <p className="note">
              Please keep in mind that it will not loop through your
              recycle bin and starred folder.
            </p>
          </div>
        </div>

        <hr/>

        <div className="pure-g">
          <div className="pure-u-1-2">
            <h2>Step 1</h2>
            <p><b>
              Make sure you're logged into Quip, then click this link:{' '}
              <a href="https://quip.com/dev/token" target="blank">https://quip.com/dev/token</a>{' '}
              to get you're access token</b>
            </p>
            <h2>Step 2</h2>
            <p><b>
              Read the instructions to the right, input your quip access token, <br/>then click the button to begin the export</b>
            </p>
            <h2>Step 3</h2>
            <p><b>Extract your downloaded files and upload to your Google Drive account</b></p>
          </div>

          <div className="pure-u-1-2">
            <h2>Important:</h2>
            <p>
              After beginning your export, keep an eye on the Logs. Depending on how many documents and how
              many images you have, the export process can last multiple hours
              (or even days) if Quip was heavily used. Quip limits the number of API
              requests per minute and per hour for each API token, so make sure you keep
              this window open until this process is complete.
              <br />
              <br />
              <b style={{ color: "red" }}>
                Do not close or refresh this window / tab while exporting.
              </b>
            </p>
            <form className="pure-form">
              <fieldset className="pure-group center">
                <input type="text" className="pure-input-1"
                  placeholder="Quip Access Token"
                  id="accesstoken"
                  name="accesstoken"
                  autoComplete="off" />
              </fieldset>

              <div>
                <div hidden={globalStore.running}>
                  <input
                    type="button"
                    value="Start exporting"
                    disabled={globalStore.exportPaused}
                    style={{ width: "100%", height: "50px" }}
                    onClick={() => this.startExporting()}
                  />
                </div>
                <div hidden={!globalStore.running}>
                  <input
                    type="button"
                    value="Pause exporting and download zip"
                    style={{ width: "100%", height: "50px" }}
                    onClick={() => this.pauseExportingByUser()}
                  />
                  <p>
                    <b style={{ color: "limegreen" }}>&#9679; Export running</b>
                  </p>
                </div>
                <div hidden={!globalStore.exportPausedByUser}>
                  <p>Your paused your export. You can download your current zip or continue with exporting.</p>
                  <input
                    type="button"
                    disabled={!globalStore.zipFileFinished}
                    value={`${globalStore.zipFileFinished ? "Download zip" : "Wait for zip..."}`}
                    id="callToAction"
                    onClick={() => this.downloadZip()}
                  />
                </div>
                <div hidden={!globalStore.exportPaused}>
                  <p>
                    You exported <b>{globalStore.numAPIcalls}</b> documents and
                    images now. Please download this
                    zip now and have a look at the files (exported in multiple formats - docx, html, and md).
                  </p>
                  <input
                    type="button"
                    disabled={!globalStore.zipFileFinished}
                    value={`${globalStore.zipFileFinished ? "Download zip" : "Wait for zip..."}`}
                    id="callToAction"
                    onClick={() => this.downloadZip()}
                  />
                </div>
                <div hidden={!globalStore.finished}>
                  <p>Your export is finished.</p>
                  <input
                    type="button"
                    disabled={!globalStore.zipFileFinished}
                    value={`${globalStore.zipFileFinished ? "Download zip" : "Wait for zip..."}`}
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "gold",
                    }}
                    onClick={() => this.downloadZip()}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <br/>
        <br/>
        <hr/>

        <h2>Export Log</h2>
        <div id="logarea">
          {globalStore.log.map((val, idx) => (
            <pre key={idx}>{val}</pre>
          ))}
        </div>
      </div>
    );
  }
}

export default view(App);
